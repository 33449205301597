.spaceContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  height: 100%;

  .eventToggler {
    display: none;
  }

  .eventSection,
  .chatSection {
    position: relative;
    max-height: 100%;
    padding: 0.5rem 0;
  }

  .chatSection {
    flex-basis: 40%;
  }

  .eventSection {
    flex-basis: 55%;
    margin-right: 3rem;
  }

  .activePeople {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    background-color: var(--app-background-color);
    width: 100%;
    z-index: 5;
    padding: 0.5rem 0;

    h4 {
      margin: 0 0.5rem;
    }
  }
}

@media screen and (max-width: 768px) {
  .spaceContainer {
    --event-count-bump-scale: 1.5;

    position: relative;

    .eventSection,
    .chatSection {
      flex-basis: 100%;
      margin: 0;
    }

    .eventToggler {
      z-index: 7;
      display: block;
      position: fixed;
      right: 0;
      top: calc(2rem + var(--header-height));
      outline: none;

      color: var(--white);
      background-color: var(--primary-green);
      font-weight: 700;
      font-size: 1.6rem;
      padding: 0.5rem 1rem;
      border-radius: 10px 0 0 10px;

      .eventCount {
        position: absolute;
        display: flex;
        background-color: var(--highlight-red);
        top: -1.2rem;
        left: -1.2rem;
        height: 2.5rem;
        width: 2.5rem;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        font-size: 1.6rem;

        animation-name: bumpEventCount;
        animation-duration: 1.5s;
        animation-delay: 1s;
        animation-play-state: running;

        &.stopBumping {
          animation-play-state: paused;
        }
      }
    }

    .eventSection {
      position: fixed;
      padding: 2rem 1rem 1rem 1rem;
      background-color: var(--app-background-color);
      right: -100vw;
      top: 0;
      bottom: 0;
      width: 0;
      visibility: hidden;
      z-index: 6;
      transition: all 0.3s ease-in-out;

      &.open {
        visibility: visible;
        right: 0;
        width: 100%;
      }
    }
  }
}

@keyframes bumpEventCount {
  from {
    transform: scale(1);
  }
  25% {
    transform: scale(var(--event-count-bump-scale));
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(var(--event-count-bump-scale));
  }
  to {
    transform: scale(1);
  }
}
