.profileImage {
  display: inline-block;
  flex-shrink: 0;
  width: 45px;
  height: 45px;

  border-radius: 100%;
  background-color: gray;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
