.button {
  outline: none;
  border: none;
  padding: 1.2rem 2rem;
  border-radius: 10px;
  color: var(--white);
  font-weight: bold;
  font-size: 1.4rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover,
  &:focus {
    filter: brightness(1.15);
  }

  &.primary {
    background-color: var(--primary-green);
  }

  &.secondary {
    background-color: var(--secondary-grey);
  }
}

@media screen and (min-width: 400px) {
  .button {
    font-size: 1.6rem;
  }
}
