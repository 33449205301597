.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: var(--max-content-width);
  width: 100%;
  height: var(--header-height);
  background-color: var(--app-background-color);
  z-index: 10;

  .logo {
    display: inline-flex;
    align-items: center;
    margin-right: 10px;

    .logoIcon {
      height: 4rem;
      width: 4rem;
    }

    .logoText {
      width: 9rem;
    }
  }

  .menu {
    display: flex;
    align-items: center;
    position: relative;

    .placeText {
      margin-right: 1rem;
      font-weight: 700;
    }

    .placeLink {
      text-decoration: none;
      color: inherit;
      display: inline-flex;
      align-items: center;
    }

    .userProfile {
      cursor: pointer;
      outline: none;
    }

    .userMenu {
      position: absolute;
      min-width: 15rem;
      background-color: var(--app-background-color);
      border: 3px solid var(--base-font-color);
      z-index: 15;
      top: 2rem;
      right: 3rem;
      border-radius: 15px;
      font-weight: bold;
      font-size: 1.6rem;
      display: none;

      &.userMenuOpen {
        display: block;
      }

      span {
        display: block;
        padding: 1rem;
        cursor: pointer;
        border-radius: 15px;

        &:hover,
        &:focus {
          background-color: var(--light-grey);
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .header {
    .logo {
      .logoIcon {
        height: 3rem;
        width: 3rem;
      }
      .logoText {
        height: 1.3rem;
        width: 8rem;
      }
    }
  }
}
