.areaFind {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  height: 100%;
  max-height: 70rem;
  text-align: center;
  transition: height 0.5s ease-in-out;

  &:before,
  &:after {
    content: '';
    display: block;
  }

  &.manual {
    height: 60%;
  }

  .setManually {
    font-size: 1.6rem;
    font-style: italic;
  }
}
