.contentWidth {
  max-width: 50rem;
  width: 100%;
}

.areaConfirm {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  text-align: center;
  position: relative;
}

.areaMap {
  composes: contentWidth;
  height: 30rem;
  min-height: 20rem;
  width: 100%;
  border: 5px solid var(--base-font-color);
  border-radius: 20px;
  margin: 3rem 0;
  overflow: hidden;

  .mapImage {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.actionButtons {
  composes: contentWidth;
  display: flex;
  justify-content: space-between;
}

.modifyLocationModal {
  composes: contentWidth;
  min-height: 70vh;
  height: 100%;
  padding: 2rem;
}
