.popup {
  --display-duration: 0.3s;

  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;

  &.visible {
    .content {
      max-height: 80vh;
    }
    .backdrop {
      visibility: visible;
      opacity: 1;
    }

    .closeBtn {
      .closeIcon {
        opacity: 1;
      }
    }
  }

  .content {
    transition: max-height var(--display-duration) ease-in;
    max-height: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 50rem;
    width: 100%;

    .contentWrapper {
      border-radius: 10px 10px 0 0;
      background-color: var(--app-background-color);
      width: 100%;
    }
  }

  .backdrop {
    visibility: hidden;
    opacity: 0;
    z-index: -1;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    transition: visibility var(--display-duration) linear, opacity var(--display-duration) linear;
  }

  .closeBtn {
    width: 100px;
    height: 20px;
    background-color: var(--app-background-color);
    border-radius: 10px 10px 0 0;
    display: inline-flex;
    align-items: flex-end;
    justify-content: center;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
    outline: none;

    .closeIcon {
      transition: opacity var(--display-duration) ease-in-out;
      opacity: 0;
    }

    &:hover,
    &:focus {
      transform: scale(1.1);
    }
  }
}
