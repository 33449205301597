.registrationSection {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  height: 100%;

  h1 {
    margin-bottom: 5rem;
  }

  .formContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    max-width: 70rem;
    width: 100%;

    .form {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;

      .submitButton {
        width: 100%;
        margin: 0.5rem 0;
      }
    }

    .spacer {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: var(--secondary-grey);
      font-style: italic;
      margin: 0 3rem;

      .line {
        display: inline-block;
        height: 7rem;
        width: 0.1rem;
        background-color: var(--tag-color);
      }

      .lineText {
        margin: 0.5rem 0;
        font-weight: bold;
      }
    }

    .social {
      flex: 1;
      width: 100%;
    }
  }

  .authError {
    margin-top: 2rem;
    color: var(--highlight-red);
    font-weight: bold;
  }

  .hasAccountLogin {
    margin-top: 2rem;

    a {
      text-decoration: none;
      font-weight: bold;
      color: var(--base-font-color);
      margin: 0 0.5rem;
    }
  }
}

@media screen and (max-width: 768px) {
  .registrationSection {
    h1 {
      margin-bottom: 2rem;
    }

    .formContainer {
      flex-direction: column;
      max-width: 40rem;

      .form {
        order: 3;
        width: 100%;
      }

      .spacer {
        order: 2;
        flex-direction: row;
        margin: 2rem 0;

        .line {
          height: 0.2rem;
          width: 7rem;
        }

        .lineText {
          margin: 0 0.5rem;
        }
      }

      .social {
        order: 1;
      }
    }
  }
}
