.app {
  margin: 0 auto;
  max-width: var(--max-content-width);
  width: 100%;
  padding: 1rem 2rem;
  height: calc(var(--vh, 1vh) * var(--max-content-height));
  box-shadow: 0px 5px 13px 0px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 15px 15px;

  .container {
    height: calc(100% - var(--header-height));
    overflow-y: auto;
  }
}
