.message {
  position: relative;
  width: 100%;

  .messageInput {
    width: 100%;
    padding-right: 4rem;
    word-break: break-all;
    max-height: 10rem;
    overflow-y: auto;
  }

  .inputEmojiPicker {
    position: absolute;
    right: 0.5rem;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;

    path {
      transition: fill 0.2s ease-in-out;
      fill: var(--base-font-color);
      fill-opacity: 1;
    }

    &:hover {
      path {
        fill: var(--primary-green);
      }
    }
  }

  .emojiPicker {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 7;
  }
}
