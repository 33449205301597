:root {
  --app-background-color: #fefefe;
  --base-font-color: #757575;
  --secondary-grey: #8c999a;
  --tag-color: #ebebeb;
  --light-grey: #f4f4f4;
  --white: #fafafa;

  --primary-green: #0eb29a;
  --blueish-green: #76dbd1;
  --light-green: #ddf0c2;
  --highlight-red: #f67280;

  --google-color: #dc4f41;
  --facebook-color: #3b5998;
  --twitter-color: #56aced;
}
