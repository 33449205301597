.formField {
  width: 100%;
  margin: 0.5rem 0;

  &.hasError {
    border: 2px solid var(--highlight-red);
  }
}

.errorText {
  color: var(--highlight-red);
  font-style: italic;
}
