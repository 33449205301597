.eventsContainer {
  display: flex;
  flex-direction: column;
  height: 100%;

  .filterTags {
    display: flex;
    flex-wrap: wrap;
    background-color: var(--app-background-color);
    padding-bottom: 1rem;

    .filterTagItem {
      margin-right: 1rem;
      margin-bottom: 0.5rem;
    }
  }

  .eventList {
    height: 100%;
    overflow-y: auto;

    .emptyEvents {
      color: var(--secondary-grey);
      font-size: 4rem;
      opacity: 0.3;
    }

    .eventItem {
      padding: 2rem;
      padding-left: 0;
    }
  }
}

@media screen and (max-width: 768px) {
  .eventsContainer {
    .eventList {
      padding: 0 0.5rem;
      .eventItem {
        padding: 2rem 0rem;
      }
    }
  }
}
