.container {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  background-color: var(--tag-color);
  padding: 0.5rem 2rem;
  font-weight: 700;
  border: 3px solid transparent;

  &.active {
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;

    &:hover,
    &:focus {
      background-color: var(--light-grey);
    }
  }

  &:hover,
  &:focus {
    outline: none;
  }

  &.selected {
    border: 3px solid var(--blueish-green);
  }
}
