.placeLoading {
  --animation-duration: 1.3s;
  --animation-state: paused;

  display: flex;
  flex-direction: column;
  align-items: center;
  width: 250px;

  &.loading {
    --animation-state: running;
  }
}

.placeIconContainer {
  height: 160px;
  width: 100%;
  position: relative;

  .placeIcon {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    animation-name: bouncingplace;
    animation-duration: var(--animation-duration);
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    animation-play-state: var(--animation-state);
  }
}

.loaderBar {
  span {
    display: inline-block;
    height: 8px;
    margin: 0 5px;
    border-radius: 2px;
    animation-duration: var(--animation-duration);
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    animation-play-state: var(--animation-state);
  }

  // longest
  & span:nth-child(3) {
    width: 80px;
    background-color: var(--primary-green);
    animation-name: middleline;
  }

  // mid lenght
  & span:nth-child(2),
  & span:nth-child(4) {
    width: 25px;
    background-color: var(--blueish-green);
    animation-name: longlines;
    animation-delay: 0.2s;
  }

  // sides
  & span:nth-child(1),
  & span:nth-child(5) {
    width: 15px;
    background-color: var(--light-green);
    animation-name: shortlines;
    animation-delay: 0.4s;
  }
}

@keyframes bouncingplace {
  from {
    top: 10px;
  }
  50% {
    top: 40px;
  }
  to {
    top: 10px;
  }
}

@mixin lineKeyframes($name, $minWidth, $maxWidth) {
  @keyframes #{$name} {
    from {
      width: $minWidth;
    }
    20% {
      width: $minWidth;
    }
    50% {
      width: $maxWidth;
    }
    to {
      width: $minWidth;
    }
  }
}

@include lineKeyframes(shortlines, 10px, 15px);
@include lineKeyframes(longlines, 10px, 25px);
@include lineKeyframes(middleline, 50px, 80px);
