.chatContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  position: relative;

  .chatFeed {
    overflow-y: auto;
    margin-top: 3.5rem;
    margin-bottom: 0.5rem;
    padding: 0 0.5rem;

    .chatItem {
      margin: 2rem 0;
    }
  }
}
