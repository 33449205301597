.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .searchInput {
    z-index: 1;
  }

  .searchInput,
  .suggestions {
    max-width: 40rem;
    width: 90%;
  }

  .suggestions {
    display: block;
    position: absolute;
    top: 0;
    opacity: 0;
    z-index: 0;
    margin: 0;
    padding: 0;
    border-top: none;
    border-radius: 0px 0px 10px 10px;
    height: 0;
    max-height: 20rem;
    overflow-y: auto;
    transition: top 0.4s ease-in-out, opacity 0.2s ease-in-out 0.1s;

    .suggestionItem {
      display: flex;
      align-items: center;
      height: 4rem;
      padding: 0.5rem;
      outline: none;
      cursor: pointer;

      &:last-child {
        border-radius: 0px 0px 10px 10px;
      }

      &:hover,
      &:focus {
        background-color: var(--light-grey);
      }
    }
  }

  &.suggestionsOpen {
    .searchInput {
      border-radius: 10px 10px 0 0;
    }
    .suggestions {
      opacity: 1;
      border: 2px solid var(--base-font-color);
      height: auto;
      top: 4rem;
    }
  }
}
