.input {
  border-radius: 10px;
  border: 2px solid var(--base-font-color);
  outline: none;
  padding: 1rem;
  font-size: 1.6rem;
  color: var(--base-font-color);
  background-color: var(--app-background-color);
  transition: all 0.2s ease-in-out;

  &:hover,
  &:focus {
    border: 2px solid var(--primary-green);
  }

  &::placeholder {
    font-weight: 300;
    font-style: italic;
    opacity: 0.7;
  }
}
