.social {
  display: flex;
  flex-direction: column;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    border: none;
    background-color: var(--white);
    margin-top: 1rem;
    cursor: pointer;
    border-radius: 10px;
    font-size: 1.6rem;
    font-weight: 700;
    height: 4.2rem;

    color: var(--base-font-color);
    background-repeat: no-repeat;
    background-position: -1rem center;
    background-size: 5rem;

    &.google {
      border: 4px solid var(--google-color);
      background-image: url('../../assets/google.svg');
      color: var(--google-color);
    }

    &.facebook {
      border: 4px solid var(--facebook-color);
      background-image: url('../../assets/facebook.svg');
      color: var(--facebook-color);
    }

    &.twitter {
      border: 4px solid var(--twitter-color);
      background-image: url('../../assets/twitter.svg');
      color: var(--twitter-color);
    }

    &:first-child {
      margin-top: 0;
    }
  }
}
