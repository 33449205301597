@import url('https://fonts.googleapis.com/css?family=Quicksand:300,400,700&display=swap');
@import './styles';

html,
body {
  overflow: hidden;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--base-font);
  font-size: var(--base-font-size);
  background-color: var(--app-background-color);
  color: var(--base-font-color);
  min-height: calc((var(--vh, 1vh) * 100));
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

[contentEditable='true']:empty:not(:focus):before {
  content: attr(data-placeholder);
  color: grey;
  font-style: italic;
  cursor: text;
}
