.chatMessageContainer {
  display: flex;

  .messageContent {
    padding: 0 1rem;

    h4 {
      display: inline;
      margin: 0;
      font-size: 1.6rem;
    }

    p {
      margin: 0.5rem 0;
      word-break: break-all;
    }

    .time {
      margin: 0 1rem;
      font-weight: 300;
    }
  }
}
