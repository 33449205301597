.eventBoxContainer {
  border: 2px solid var(--secondary-grey);
  border-radius: 20px;
  box-shadow: 3px 3px 0px var(--secondary-grey);
  padding: 1.5rem;

  .content {
    display: flex;
    align-items: center;

    .type {
      text-align: center;
      padding-right: 0.5rem;

      svg {
        width: 7rem;
      }
    }

    .description {
      word-break: break-word;
      h1 {
        color: var(--primary-green);
        margin: 0;
        font-size: 2.5rem;
      }

      h3,
      p {
        margin: 0.5rem 0;
      }

      .tags {
        display: inline-block;
        margin: 1rem 0;
        font-style: italic;
      }
    }
  }

  footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.5rem;

    .personGoing {
      display: flex;
      align-items: center;

      span {
        color: var(--primary-green);
        font-weight: 700;
        font-size: 1.8rem;
      }

      span,
      p {
        margin: 0;
        margin-left: 0.5rem;
      }
    }

    .joinButton {
      padding: 1rem 3rem;
    }
  }
}

@media screen and (max-width: 768px) {
  .eventBoxContainer {
    .content {
      flex-direction: column;

      .type {
        order: 2;
        display: flex;
        width: 100%;
        margin: 1rem 0;

        svg {
          display: none;
        }
      }
    }
  }
}
