:root {
  --base-font: 'Quicksand', sans-serif;
  --default-font-size: 62.5%; // 1rem == 10px
  --base-font-size: 1.4rem; // 14px for base font
  --max-content-width: 1024px;
  --max-content-height: 98;
  --header-height: 6rem;

  font-size: var(--default-font-size);
}

@media screen and (min-width: 1400px) {
  :root {
    --max-content-width: 1280px;
  }
}

@media screen and (min-width: 768px) {
  :root {
    --max-content-height: 95;
  }
}
